import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserService from '../services/UserService';
import "./css/style.css"
import logo from "../assets/logo.png"
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const { REACT_APP_REDIRECT_URI } = process.env
function NavBar() {
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    useEffect(() => {
        // jazzUp()
    }, [])
    const jazzUp = () => {
        let lastScrollPosition = 0;
        const navbar = document.querySelector('.navbar');
        const nodeList = document.querySelectorAll(".drk")
        window.addEventListener('scroll', function (e) {
            lastScrollPosition = window.scrollY;
            if (lastScrollPosition > 500) {
                navbar.classList.add('navbar-dark');
                navbar.classList.add('bg-dark');
                nodeList.forEach((e) => { e.classList.add('btn-dark') })
            }
            else {
                navbar.classList.remove('navbar-dark');
                navbar.classList.remove('bg-dark');
                nodeList.forEach((e) => e.classList.remove('btn-dark'))
            }
        });
    }

    return [
        <>
            <nav
                id="navy" className="navbar sticky-top navbar-expand-lg navbar-light navbar-custom"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
            >
                <Link className="navbar-brand pl-4" to="/">
                    <img src={logo} style={{ width: "100px" }} alt="logo" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item active" key={1}>
                            <Link className="nav-link" to="/">
                                <button className="btn btn-light drk"><i className="fas fa-home"></i> &nbsp; Home</button>
                            </Link>
                        </li>
                        <li className="nav-item active" key={3}>
                            <button className="nav-link">
                                <button onClick={() => {
                                    if (window.location.href === REACT_APP_REDIRECT_URI) document.getElementById("firstName").focus()
                                    else {
                                        navigate("/")
                                        setTimeout(() => document.getElementById("firstName").focus(), 1000)
                                    }
                                }} className="btn btn-light drk"><i className="fas fa-envelope"></i>&nbsp; Contact Us</button>
                            </button>
                        </li>
                        {/* <li className="nav-item active" key={4}>
                        <Link className="nav-link" to="/pricing">
                            <button className="btn btn-light"><i className="far fa-money-bill-alt"></i> Pricing</button>
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li> */}
                        <li id="tryNow" className={UserService.isLoggedIn() ? "nav-item active" : "d-none"} key={5}>
                            <Link className="nav-link" to="/upload">
                                <button className="btn btn-outline-info"><i className="fas fa-upload"></i>&nbsp;Try Now!</button>
                            </Link>
                        </li>
                        <li className={UserService.isLoggedIn() ? "nav-item active" : "d-none"} key={6}>
                            <button className="nav-link">
                                <button className="btn btn-outline-info"><i className="fas fa-user-tie"></i> &nbsp; Hi {UserService.getUsername()}</button>
                            </button>
                        </li>
                        <li className={UserService.isLoggedIn() ? "nav-item active" : "d-none"} key={7}>
                            <Link className="nav-link">
                                <button className="btn btn-info" onClick={() => {
                                    UserService.doLogout({ redirectUri: `${REACT_APP_REDIRECT_URI}` })
                                }}
                                ><i className="fas fa-sign-out-alt"></i> &nbsp; Log Out</button>
                            </Link>
                        </li>
                        <li className={UserService.isLoggedIn() ? "d-none" : "nav-item active"} key={8}>
                            <button className="nav-link" onClick={() => {
                                UserService.doLogin()
                                // console.log(UserService.getToken());
                            }}>
                                <button onClick={() => {
                                    if (sessionStorage.getItem("error") === "Server Down") {
                                        setError(true)
                                        setTimeout(() => setError(false), 3000)
                                    }
                                }}
                                    className="btn btn-outline-info"><i className="fas fa-sign-in-alt"></i> &nbsp; Login</button>
                            </button>
                        </li>
                        <li className={UserService.isLoggedIn() ? "d-none" : "nav-item active"} key={9}>
                            <button className="nav-link" onClick={() => UserService.doSignUp()}>
                                <button onClick={() => {
                                    if (sessionStorage.getItem("error") === "Server Down") {
                                        setError(true)
                                        setTimeout(() => setError(false), 3000)
                                    }
                                }} className="btn btn-info"><i className="fas fa-user-plus"></i> &nbsp; Sign Up</button>
                            </button>
                        </li>
                    </ul>
                </div>
            </nav >
            <div className={error ? "alert alert-danger text-center" : "d-none"} role="alert" >Server is Down. Please try login or signup after sometime.</div>
        </>
    ]
}

export default NavBar



//need to add the promt revanth told of promot server not running
