import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserService from './services/UserService';
import HttpService from './services/HttpService';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'


//optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '75px',
  // you can also just use 'scale'  
  transition: transitions.SCALE
}


const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => root.render(

  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>

);
// const renderApp = () => ReactDOM.render(<App/>, document.getElementById("app"));
// if (UserService.initKeycloak()) {
//   console.log("hi");
//   UserService.initKeycloak(renderApp);
//   HttpService.configure();
// }
// else {
//   console.log("bye");
//   renderApp()
// }
UserService.initKeycloak(renderApp);
HttpService.configure();