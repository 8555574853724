import React, { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import jQuery from 'jquery';
import { useAlert } from 'react-alert';
const { REACT_APP_CONTACT_MAIL_URL } = process.env;

const Contact = () => {
  useEffect(() => {
    if (document.getElementById("tryNow")) document.getElementById("tryNow").style.display = "block"
  }, [])
  const [loader, setLoader] = useState(false)
  const alert = useAlert()

  const [validateFirstName, setValidateFirstName] = useState(false)
  const [validateLastName, setValidateLastName] = useState(false)
  const [validateEmail, setValidateEmail] = useState(false)


  const sendData = async (e) => {
    e.preventDefault()
    const firstName = document.getElementById("firstName").value
    const lastName = document.getElementById("lastName").value
    const email = document.getElementById("email").value
    const phone = document.getElementById("phone").value
    const organisation = document.getElementById("organisation").value
    const designation = document.getElementById("designation").value
    const query = document.getElementById("query").value
    const cleanData = () => {
      document.getElementById("firstName").value = ""
      document.getElementById("lastName").value = ""
      document.getElementById("email").value = ""
      document.getElementById("phone").value = ""
      document.getElementById("organisation").value = ""
      document.getElementById("designation").value = ""
      document.getElementById("query").value = ""
    }

    if (firstName === "") {
      setValidateFirstName(true)
      document.getElementById("firstName").focus()
      setTimeout(() => {
        setValidateFirstName(false)
      }, 3000)
    }
    if (lastName === "") {
      setValidateLastName(true)
      setTimeout(() => {
        setValidateLastName(false)
      }, 3000)
    }
    if (email === "") {
      setValidateEmail(true)
      setTimeout(() => {
        setValidateEmail(false)
      }, 3000)
    }
    if (firstName !== "" && lastName !== "" && email !== "") {
      try {
        setLoader(true)
        const FormData = require("form-data")
        const sendData = new FormData()
        sendData.append('firstName', firstName)
        sendData.append('secondName', lastName)
        sendData.append('email', email)
        sendData.append('phone', phone)
        sendData.append('organisation', organisation)
        sendData.append('designation', designation)
        sendData.append('query', query)

        const config = {
          method: "post",
          url: REACT_APP_CONTACT_MAIL_URL,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: sendData,
        }
        const getResponse = await axios(config)
        if (getResponse.status === 200) {
          setLoader(false)
          cleanData()
          alert.success("Your Mail has been sent successfully. Our Team will get back to you soon")
        }
        else {
          alert.error("Mail is invalid. Form is not Submitted. Try Again")
          setLoader(false)
        }
      }
      catch (err) {
        setLoader(false)
        console.log("Error", err);
        alert.error("Form is not Submitted. Try Again")
      }

    }
  }
  const check = () => {
    const email = document.getElementById("email")
    if (email.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
       email.classList.add("is-valid")
      setTimeout(()=>{email.classList.remove("is-valid")},3000)
      }
  }

  return (
    <div className="col-sm animatable bounceIn">
      <div className='p-4 newContact shadow'>
        <form>
          <div className="form-floating mb-3 contactSpace">
            <input type="text" className={validateFirstName ? "form-control is-invalid" : "form-control"} id="firstName" placeholder="First Name" />
            <label htmlFor="floatingInput">First Name<span className='text-danger'>*</span></label>
            {
              validateFirstName ?
                <div id="" className="invalid-feedback">
                  Please Fill First Name.
                </div> : ""
            }
          </div>
          <div className="form-floating mb-3 contactSpace">
            <input type="text" className={validateLastName ? "form-control is-invalid" : "form-control"} id="lastName" placeholder="Last Name" />
            <label htmlFor="floatingInput">Last Name<span className='text-danger'>*</span></label>
            {
              validateLastName ?
                <div id="" className="invalid-feedback">
                  Please Fill Last Name.
                </div> : ""
            }
          </div>
          <div className="form-floating mb-3 contactSpace">
            <input onChange={() => { check() }} type="email" className={validateEmail ? "form-control is-invalid" : "form-control"} id="email" placeholder="name@example.com" />
            <label htmlFor="floatingInput">Email Address<span className='text-danger'>*</span></label>
            {
              validateEmail ?
                <div id="" className="invalid-feedback">
                  Please Fill Email Address.
                </div> : ""
            }
          </div>
          <div className="form-floating mb-3 contactSpace">
            <input type="tel" className="form-control" id="phone" placeholder="name@example.com" />
            <label htmlFor="floatingInput">Phone Number</label>
          </div>
          <div className="form-floating mb-3 contactSpace">
            <input type="text" className="form-control" id="organisation" placeholder="name@example.com" />
            <label htmlFor="floatingInput">Organisation</label>
          </div>
          <div className="form-floating mb-3 contactSpace">
            <input type="text" className="form-control" id="designation" placeholder="name@example.com" />
            <label htmlFor="floatingInput">Designation</label>
          </div>
          <div className="form-floating mb-3 contactSpace">
            <textarea className="form-control" placeholder="Leave a comment here" id="query" style={{ height: "103px" }}></textarea>
            <label htmlFor="floatingTextarea2">Query</label>
          </div>
          <button onClick={(e) => {
            sendData(e)
          }} id='trynow' type="submit" className="btn btn-info">{loader ? <div className="spinner-border" role="status"></div> : "Submit"}</button>
        </form>
      </div>
    </div>
  )
}

export default Contact

