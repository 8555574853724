import React from 'react'
import { Link } from "react-router-dom"
const ErrorPage = () => {
  return (
    <div className='text-center text-dark'>
      <h1 style={{ fontSize: "150px", marginTop: "20vh" }}>404</h1>
      <h3>Page Not Found</h3>
      <p>The resource requested could not be found on this server!</p>
      <Link className="btn btn-info" to="/">Home</Link>
    </div>
  )
}

export default ErrorPage