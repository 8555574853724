import React from 'react'
import UserService from '../services/UserService';
import axios from "axios"
import { Bars } from 'react-loader-spinner'
import { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { useAlert } from 'react-alert';

const { REACT_APP_UPLOAD_URL, REACT_APP_REDIRECT_URI } = process.env
const fileTypes = ["XLX", "XLXS", "DOCX", "TXT"];

const UploadFile = () => {
  if (document.getElementById("tryNow")) document.getElementById("tryNow").style.display = "none"
  const alert = useAlert()

  const [loader, setLoader] = useState(false)

  const [file1, setFile1] = useState({});
  const [file2, setFile2] = useState({});
  const [no1, setNo1] = useState("0")
  const [no2, setNo2] = useState("0")
  const [bootAlert1, setBootAlert1] = useState(false)
  const [bootAlert2, setBootAlert2] = useState(false)
  const [sessionError, setSessionError] = useState(false)
  // const [courseField, setCourseField] = useState(false)


  const handleChange1 = (file) => {
    setFile1(file);
    setNo1(file.length)
    setBootAlert1(true)
    // setTimeout(() => { setBootAlert1(false) }, 3000)

  };
  const handleChange2 = (file) => {
    setFile2(file);
    setNo2(file.length)
    setBootAlert2(true)
    // setTimeout(() => { setBootAlert2(false) }, 3000)
  };


  const getUserID = () => {
    const token = UserService.getToken()
    const subs = parseJwt(token)
    const sub = subs.sub
    return sub
  }
  const getUserSessionId = () => {
    const token = UserService.getToken()
    const subs = parseJwt(token)
    const sub = subs.sid
    return sub
  }
  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };

  const getQuestionAndModelAnswerAndStudentSubmission = async () => {
    // const course = document.getElementById("course")
    // if (course.value === "") {
    //   setCourseField(true)
    //   course.focus()
    //   setTimeout(() => {
    //     setCourseField(false)
    //   }, 3000)
    // }
    // else if (UserService.isLoggedIn()) {
      if (UserService.isLoggedIn()) {
      const questionAndModelAnswer = file1
      const studentSubmissions = file2
      const Number_of_files1 = Object.keys(questionAndModelAnswer).length
      const Number_of_files2 = Object.keys(studentSubmissions).length

      const Number_of_files = Number_of_files1 + Number_of_files2
      if (Number_of_files1 === 0) alert.show("Upload Questions and Model Answers")
      else if (Number_of_files2 === 0) alert.show("Upload Upload Student Submissions")
      else {
        setLoader(true)
        const checksum = "2318"
        UserService.getUsername()
        const username = UserService.getUsername()
        const getFullName = UserService.getFullName()
        const getEmail = UserService.getEmail()
        let timestamp = Date().slice(0, 25)
        const randomId = () => Math.floor(Math.random() * Date.now())
        // api
        try {
          const FormData = require("form-data")
          const sendData = new FormData()
          sendData.append('request_id', randomId())
          sendData.append('check_sum', checksum)
          sendData.append('session_id', getUserSessionId())
          sendData.append('number_of_files', JSON.stringify(Number_of_files))
          sendData.append('user_name', username)
          sendData.append('full_name', getFullName)
          sendData.append('email_id', getEmail)
          sendData.append('time_stamp', timestamp)
          sendData.append('user_id', getUserID())
          sendData.append('access_token', UserService.getToken())
          // sendData.append('course', course.value)
          for (let i = 0; i < questionAndModelAnswer.length; i++) {
            sendData.append('files1', questionAndModelAnswer[i])
          }
          for (let i = 0; i < studentSubmissions.length; i++) {
            sendData.append('files2', studentSubmissions[i])
          }
          const config = {
            method: "post",
            url: REACT_APP_UPLOAD_URL,
            headers: {
              "Accept": "application/json",
              "Content-Type": "multipart/form-data",
            },
            data: sendData,
          }
          const getResponse = await axios(config)
          if (getResponse.status === 200 && getResponse.data.number_of_files === Number_of_files) {
            // console.log(getResponse.data);
            setLoader(false)
            alert.success("Uploaded Successfully! Our Team Will Get Back To You Within 48 Hours.")
            console.log("Success");
          }
          else {
            console.log(getResponse.data);
            setLoader(false)
            alert.error("Upload Error! Please Upload It Again")
            console.log("Partial Fail");
          }
        }
        catch (err) {
          console.log("Error", err);
          setLoader(false)
          alert.error("Data Is Not Submitted. Please Try Again")
        }
      }
    }
    else {
      document.getElementById("session").scrollIntoView()
      setSessionError(true)
      setTimeout(() => {
        setSessionError(false)
        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/upload` })
        // UserService.updateToken()
      }, 1500)
    }
  }

  return (
    <>
      < div id="session" className={sessionError ? "alert alert-danger text-center" : "d-none"} role="alert" > Session Expired! Please Login Again.</div >
      <div className="container">
        <div id='upload' className="row">
          {/* <div className="form-floating mb-3 contactSpace">
            <input type="text" className={courseField ? "form-control is-invalid" : "form-control"} id="course" placeholder="Enter Course Name" />
            <label htmlFor="floatingInput">&nbsp;&nbsp;Course Name<span className='text-danger'>*</span></label>
            {
              courseField ?
                <div id="" className="invalid-feedback">
                  Please Fill Course Name.
                </div> : ""
            }
          </div> */}
          <div className="col-sm d-flex justify-content-around mt-2">
            <div className="card p-2" style={{ width: "30rem", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
              <div className="card-body" >
                <div className="alert alert-primary" role="alert">
                  <h4 className="card-title text-dark">Click Or Drag And Drop To Upload Questions And Model Answers</h4>
                </div>
                <p className="card-text">Student submissions are evaluated based on the question and model answers.</p>
                <p className="card-text">Upload the test questions along with model answers/conceptually accurate answers for each.</p>
                <p className="card-text">Our system works only with typewritten English language tests, cannot read drawings or other elements than text.</p>
                <FileUploader handleChange={handleChange1} name="file" types={fileTypes} multiple />
                {bootAlert1 && no1 > 0 ? <div className="alert alert-success" role="alert">
                  <i className="fas fa-check"></i> {" "}
                  Successfully Uploaded {no1} File{no1 > 1 ? "s" : ""}!
                </div> : ""}
              </div>
            </div>
          </div>
          <div className="col-sm d-flex justify-content-around mt-2">
            <div className="card p-2" style={{ width: "30rem", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
              <div className="card-body">
                <div className="alert alert-primary" role="alert">
                  <h4 className="card-title text-dark">Click Or Drag And Drop To Upload Students Submissions</h4>
                </div>
                <p className="card-text">Upload Individual Student Submissions For A Particular Test Exercise As Separate Docx Files</p>
                <p className="card-text marginDown">You can choose more than one by highlighting multiple files when attaching them here</p>
                <br /><br />
                <FileUploader handleChange={handleChange2} name="file" types={fileTypes} multiple />
                {bootAlert2 && no2 > 0 ? <div className="alert alert-success" role="alert">
                  <i className="fas fa-check"></i> {" "}
                  Successfully Uploaded {no2} File{no2 > 1 ? "s" : ""}!
                </div> : ""}
              </div>
            </div>
          </div>
          {loader ?
            <span
              id="loader"
            >
              <Bars
                height="80"
                width="80"
                color="#138496"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </span>
            :
            <button onClick={() => { getQuestionAndModelAnswerAndStudentSubmission() }}
              id="uploadButton" className="btn btn-info padding mx-auto">
              Submit</button>
          }
        </div>
        <br /> <br />
      </div>
    </>
  )
}

export default UploadFile