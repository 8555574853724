import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous"
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated"

import NavBar from './components/NavBar';
import Home from './components/Home';
import Pricing from './components/tailwindComponents/Pricing';
import UploadFile from "./components/UploadFile"
import ErrorPage from './components/ErrorPage';
import "./components/css/style.css"
import { useEffect, useState } from 'react';
import UserService from './services/UserService';
function App() {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (UserService.isLoggedIn()) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 5000)
    }
  }, [])
  return (
    <BrowserRouter>
      <RenderOnAnonymous>
        <NavBar />
        <Routes>
          <Route path="/" element={< Home />} />
          {/* <Route path="/pricing" element={< Pricing />} /> */}
          <Route path="*" element={< ErrorPage />} />
        </Routes>
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <NavBar />
        {show ? <div className="alert alert-success text-center" role="alert">
          Hi {UserService.getFullName()}, Welcome To Deepsy.
        </div> : ""}
        <Routes>
          <Route path="/" element={< Home />} />
          {/* <Route path="/pricing" element={< Pricing />} /> */}
          <Route path="/upload" element={< UploadFile />} />
          <Route path="*" element={< ErrorPage />} />
        </Routes>
      </RenderOnAuthenticated>
    </ BrowserRouter>
  );
}

export default App;
