import React, { useEffect } from 'react'
import Footer from './tailwindComponents/Footer'
import UserService from '../services/UserService';
import { useNavigate } from 'react-router-dom';
import jQuery from 'jquery';
import Contact from './Contact';


const { REACT_APP_REDIRECT_URI } = process.env

const Home = () => {
    const navigate = useNavigate()
    if (document.getElementById("tryNow")) document.getElementById("tryNow").style.display = "block"
    useEffect(() => {
        mobileViewHeading()
        jazzingUp()
    }, [])
    const mobileViewHeading = () => {
        const width = window.innerWidth
        if (width <= 600) document.getElementById("new").style.fontSize = "30px"
        if (width <= 600) document.getElementById("new2").style.fontSize = "23px"   
    }
    const jazzingUp = () => {
        jQuery(function ($) {
            // Function which adds the 'animated' class to any '.animatable' in view
            var doAnimations = function () {

                // Calc current offset and get all animatables
                var offset = $(window).scrollTop() + $(window).height(),
                    $animatables = $('.animatable');

                // Unbind scroll handler if we have no animatables
                if ($animatables.length === 0) {
                    $(window).off('scroll', doAnimations);
                }

                // Check all animatables and animate them if necessary
                $animatables.each(function (i) {
                    var $animatable = $(this);
                    // if (($animatable.offset().top + $animatable.height()) > offset) {
                    $animatable.removeClass('animatable').addClass('animated');
                    // }
                });

            };

            // Hook doAnimations on scroll, and trigger a scroll
            $(window).on('scroll', doAnimations);
            $(window).trigger('scroll');

        });
    }
    const card = [

        {
            imgSrc: <i className="fas fa-exchange-alt fa-3x iconColor"></i>,
            cardTitle1: "Speed",
            cardText: "Deepsy leverages proprietary technology and streamlined processes to deliver results predictably, and on time. ",
        },
        {
            imgSrc: <i className="fas fa-chess-rook fa-3x iconColor"></i>,
            cardTitle1: "Accuracy",
            cardText: "Objective rankings and scores. Accurate feedback.  Outcomes for your students will withstand challenges, scrutiny and doubt. ",
        },
        {
            imgSrc: <i className="fas fa-infinity fa-3x iconColor"></i>,
            cardTitle1: "Scale",
            cardText: "AI driven system with no limits on how many student submissions and tests you can run. ",
        },
        {
            imgSrc: <i className="fas fa-dollar-sign fa-3x iconColor"></i>,
            cardTitle1: "Efficiency",
            cardText: "Lean and cost effective to adapt for use and scale in your organisation. ",
        }
    ]
    return (
        <div id='HOME'>
            <div className="row p-4">
                <div className="col-sm shadow animatable bounceIn">
                    <h1 id="new" style={{ fontSize: "50px" }} className='heading'>Make your student assessments efficient, objective and reliable.</h1>
                    <h4 id="new2" style={{ fontSize: "30px" }} className='heading mb-3'>Transform your evaluation process with the power of AI.</h4>
                    <p>
                        Deepsy specialises in assessing student submissions in tests, exams, class exercises in a completely accurate and objective manner. Using Deepsy to score your students and providing feedback empowers your instructors to do more, while enhancing the quality and objectivity of your assessment process.
                    </p>
                    <div className='p-3'>
                        <ul style={{ listStyle: "disc" }}>
                            <li>We understand each institution is different and instructors have varying expectations. We work with them, not around them. </li>
                            <li>Deepsy is powered by AI models and dedicated teams to ensure speed and accuracy that are not comparable to existing methods and processes.  </li>
                            <li>While enhancing fairness in evaluations, your examination process is also more likely seen to be fair. So, more tests, less controversy.</li>
                        </ul>
                    </div>
                    <button id="trynow" className="btn btn-info mt-2 mb-2" onClick={() => {
                        if (UserService.isLoggedIn()) navigate("/upload")
                        else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}upload` })
                    }}>Try Now</button>
                </div>
                <Contact />
            </div>
            <div className="row">
                <div className='content text-center'>
                    <h5 className='mt-2' style={{ fontFamily: "sans-serif" }}>A COMPLETE SOLUTION</h5>
                    <h1 style={{ fontSize: "40px" }} className='heading mt-4'>Your students deserve the best</h1>
                    <h5 className='mt-4' style={{ fontFamily: "sans-serif" }}>Your testing, assessment and evaluation process can be exceptional.</h5>
                    <div className="row mt-4">
                        {card.map((card, ind) => (
                            <div key={ind} className="col-sm d-flex justify-content-around mt-2">
                                <div className="text-center p-4 shadow">
                                    {card.imgSrc}
                                    <div className="card-body">
                                        <h3 className="card-title heading">{card.cardTitle1}</h3>
                                        <p className="card-text">{card.cardText}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button style={{ width: "200px", height: "50px", fontSize: "20px", marginTop: "50px" }} className="btn btn-info mb-2" onClick={() => {
                        if (UserService.isLoggedIn()) navigate("/upload")
                        else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}upload` })
                    }}>Try Now</button>

                </div>
            </div>
            {/* <div className='row shadow'>
                <div className="col-sm  animatable bounceIn">
                    <div className='p-4 text-center' >
                        <h4> Want to learn more about how Deepsy can help you with Speed Objectivity Accuracy.</h4>
                        <p> Contact Us for more details</p>
                    </div>
                </div>
                <div className="col-sm">
                    <div style={{ marginTop: "8%" }} className='text-center'>
                        <Link id="trynow" className='btn btn-info' to="/contact">Contact Us</Link>
                    </div>
                </div>
            </div> */}
            <Footer />
        </div>
    )
}

export default Home
